<template>
  <div class="debts-providers">
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <!-- MAIN CONTAINER -->
    <b-row>
      <b-col md="12" lg="12" sm="12">
        <KTCodePreview>
          <!-- CONTENT -->
          <template v-slot:preview>
            <!-- FILTER CONTENT -->
            <b-row class="mb-5">
              <b-col md="2">
                <b-input-group>
                  <date-picker
                    placeholder="Từ"
                    class="form-control form-control-sm"
                    :config="dpConfigs.date"
                    v-model="apiParams.fromDate"
                  ></date-picker>
                </b-input-group>
              </b-col>
              <b-col md="2">
                <b-input-group>
                  <date-picker
                    placeholder="Đến"
                    class="form-control form-control-sm"
                    :config="dpConfigs.date"
                    v-model="apiParams.toDate"
                  ></date-picker>
                </b-input-group>
              </b-col>
              <b-col md="3">
                <Autosuggest
                  :model="searchProvider"
                  :suggestions="filteredOptionsProvider"
                  placeholder="nhà cung cấp"
                  :limit="200"
                  @select="onSelectedProvider"
                  @change="onInputChangeProvider"
                  suggestionName="suggestionName"
                />
              </b-col>
              <b-col md="1">
                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  @click="fetchMainData()"
                  >Lọc</b-button
                >
              </b-col>
            </b-row>
            <!-- LIST DATA -->
            <b-row>
              <b-col md="12" lg="12" sm="12">
                <b-table
                  :items="mainList.dataset"
                  :fields="tableFields"
                  :busy="onLoadingList"
                  class="table-bordered table-hover"
                >
                  <template slot="thead-top">
                    <tr>
                      <th class="text-center">STT</th>
                      <th class="text-center">Nhà cung cấp</th>
                      <th class="text-center" colspan="2">
                        Phát sinh trong kì
                      </th>
                      <th class="text-center" colspan="2">Số dư cuối kì</th>
                      <th
                        class="text-center"
                        v-if="isShow('TRANSACTION_INSERT')"
                      >
                        <i class="fa fa-cogs" />
                      </th>
                    </tr>
                  </template>
                  <template v-slot:table-busy>
                    <vcl-table :speed="5" :animate="true" :columns="10" />
                  </template>
                  <template v-slot:cell(index)="row">
                    {{ row.index + 1 + getOffsetItem }}
                  </template>
                  <template v-slot:cell(name)="row">
                    <a
                      class="font-weight-bold link"
                      v-bind:href="`#/providers/update-provider?id=${row.item.id}`"
                      target="_blank"
                    >
                      <span style="display: block">{{
                        `NCC.${row.item.name}`
                      }}</span>
                      <span>{{ `ĐT: ${row.item.phoneNo}` }}</span>
                    </a>
                  </template>
                  <template v-slot:cell(paidAmount)="row">
                    {{ convertPrice(row.item.paidAmount) }}
                  </template>
                  <template v-slot:cell(amount)="row">
                    {{ convertPrice(row.item.amount) }}
                  </template>
                  <template v-slot:cell(debtSum)="row">
                    <span v-if="row.item.paidAmount > row.item.amount">
                      {{ convertPrice(row.item.paidAmount - row.item.amount) }}
                    </span>
                  </template>
                  <template v-slot:cell(paidSum)="row">
                    <span v-if="row.item.amount > row.item.paidAmount">
                      {{ convertPrice(row.item.amount - row.item.paidAmount) }}
                    </span>
                  </template>
                  <template v-slot:cell(actions)="row">
                    <div class="d-flex justify-content-center">
                      <b-dropdown size="sm" id="dropdown-left" right>
                        <template slot="button-content">
                          <i
                            style="font-size: 1rem; padding-right: 0px"
                            class="flaticon2-settings"
                          ></i>
                        </template>
                        <b-dropdown-item @click="linkToDetail(row.item)">
                          <span style="color: #3f4254; font-size: 12px">
                            <i style="font-size: 1rem" class="flaticon-eye"></i>
                            &nbsp; Chi tiết
                          </span>
                        </b-dropdown-item>
                        <b-dropdown-item>
                          <router-link
                            :to="{
                              name: 'transaction',
                              params: {
                                mainModelProp: {
                                  contactType: '2',
                                  type: '2',
                                  contactId: row.item.id,
                                  contactName: row.item.name,
                                },
                                mode: 'cash',
                                action: 'add',
                                id: 'new',
                              },
                            }"
                          >
                            <span style="color: #3f4254; font-size: 12px">
                              <i
                                style="font-size: 1rem"
                                class="far fa-money-bill-alt"
                              ></i>
                              &nbsp; Lập phiếu chi
                            </span>
                          </router-link>
                        </b-dropdown-item>
                        <b-dropdown-item>
                          <router-link
                            :to="{
                              name: 'transaction',
                              params: {
                                mainModelProp: {
                                  contactType: '2',
                                  type: '4',
                                  contactId: row.item.id,
                                  contactName: row.item.name,
                                },
                                mode: 'bank',
                                action: 'add',
                                id: 'new',
                              },
                            }"
                          >
                            <span style="color: #3f4254; font-size: 12px">
                              <i
                                style="font-size: 1rem"
                                class="fas fa-money-check-alt"
                              ></i>
                              &nbsp; Lập phiếu báo nợ
                            </span>
                          </router-link>
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </template>
                  <template slot="bottom-row">
                    <td colspan="2" class="text-right font-weight-bolder">
                      <span>Tổng</span>
                    </td>
                    <td class="text-right font-weight-bolder">
                      <span>{{ convertPrice(sumAmount('paidAmount')) }}</span>
                    </td>
                    <td class="text-right font-weight-bolder">
                      <span>{{ convertPrice(sumAmount('amount')) }}</span>
                    </td>
                    <td class="text-right font-weight-bolder">
                      <span>{{
                        convertPrice(sumCalAmount('paidAmount', 'amount'))
                      }}</span>
                    </td>
                    <td class="text-right font-weight-bolder">
                      <span>{{
                        convertPrice(sumCalAmount('amount', 'paidAmount'))
                      }}</span>
                    </td>
                    <td v-if="isShow('TRANSACTION_INSERT')"></td>
                  </template>
                </b-table>
              </b-col>
            </b-row>
            <!-- PAGINATION -->
            <b-row>
              <b-col lg="3" md="3" sm="12">
                <p class="mt-3 text-dark" style="font-weight: 500">
                  Tổng số:
                  {{ mainList.total }}
                </p>
              </b-col>
              <b-col lg="9" md="9" sm="12">
                <b-pagination-nav
                  class="customPagination"
                  v-show="mainList.pages >= 2"
                  :link-gen="linkGen"
                  :number-of-pages="mainList.pages"
                  use-router
                  @change="fetchMainData()"
                  align="right"
                  first-class="page-item-first btn btn-icon btn-sm m-1"
                  prev-class="page-item-prev btn btn-icon btn-sm m-1"
                  next-class="page-item-next btn btn-icon btn-sm m-1 "
                  last-class="page-item-last btn btn-icon btn-sm m-1 "
                  page-class="btn btn-icon btn-sm border-0 m-1"
                >
                  <template v-slot:first-text>
                    <span>
                      <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                    </span>
                  </template>

                  <template v-slot:prev-text>
                    <i class="ki ki-bold-arrow-back icon-xs"></i>
                  </template>

                  <template v-slot:next-text>
                    <i class="ki ki-bold-arrow-next icon-xs"></i>
                  </template>

                  <template v-slot:last-text>
                    <span class="text-info">
                      <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                    </span>
                  </template>
                </b-pagination-nav>
              </b-col>
            </b-row>
          </template>
        </KTCodePreview>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import Loader from '@/view/content/Loader.vue';
import { VclTable } from 'vue-content-loading';
import ApiService from '@/core/services/api.service';
import { cmdUrl } from '@/utils/apiUrl';
import { getToastInstance } from '@/utils/toastHelper';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import { checkPermissions } from '@/utils/saveDataToLocal';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import { convertPrice } from '@/utils/common';

const thStyleCommon = {
  textAlign: 'center',
  fontWeight: 600,
  color: '#181c32',
  width: '5%',
  whiteSpace: 'break-spaces',
};

export default {
  data() {
    return {
      title: 'Công nợ nhà cung cấp',
      mainList: {
        dataset: [],
        total: 0,
        pages: 1,
      },
      apiParams: {
        page: 1,
        pageSize: 10,
        fromDate: null,
        toDate: null,
        providerId: null,
      },
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      onLoadingList: false,
      tableFields: [
        {
          key: 'index',
          label: '[1]',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '5%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'name',
          label: '[2]',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: 'calc(35% - 50px)',
          },
        },
        {
          key: 'paidAmount',
          label: 'Nợ\n[3]',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '15%',
          },
          tdClass: 'text-right break-line',
        },
        {
          key: 'amount',
          label: 'Có\n[4]',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '15%',
          },
          tdClass: 'text-right break-line',
        },
        {
          key: 'debtSum',
          label: 'Nợ [Phải thu]\n= 3 - 4',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '15%',
          },
          tdClass: 'text-right break-line',
        },
        {
          key: 'paidSum',
          label: 'Có [Phải trả]\n= 4 - 3',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '15%',
          },
          tdClass: 'text-right break-line',
        },
        {
          key: checkPermissions('TRANSACTION_INSERT') ? 'actions' : '',
          label: '',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '50px',
          },
        },
      ],
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      filteredOptionsProvider: [],
      optionsProvider: [
        {
          data: [],
        },
      ],
      searchProvider: '',
      providerSelected: null,
    };
  },
  components: {
    KTCodePreview,
    Loader,
    VclTable,
    Autosuggest
  },
  mounted() {
    this.loadBreadcum();
  },
  created() {
    this.fetchProvider();
    this.loadBreadcum();
    this.fetchMainData();
  },
  methods: {
    convertPrice,
    ...getToastInstance(),
    fetchMainData: async function () {
      let page = this.$route.query.page || 1;
      if (!page) {
        page = 1;
      }
      if (this.searchProvider.trim() === '') {
        this.providerSelected = null;
      }
      this.apiParams.page = page;
      this.apiParams.type = this.$route.params.mode;
      this.apiParams.providerId = this.providerSelected;

      this.onLoadingList = true;
      ApiService.query(cmdUrl.DebtUrl.debtProviders, {
        params: { ...this.apiParams },
      })
        .then((response) => {
          const respData = response.data.data;
          this.mainList = respData;
          this.onLoadingList = false;
        })
        .catch((err) => {
          const message = err.response.data.message;
          this.makeToastFailure(message);
          this.onLoadingList = false;
        });
    },
    loadBreadcum: function () {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: 'Kế toán', route: 'accountant' },
        { title: 'Công nợ' },
        { title: this.title },
      ]);
    },
    sumAmount(key) {
      const outItems = this.mainList.dataset;
      return _.reduce(
        outItems,
        (sum, item) => {
          return sum + parseFloat(item[key]);
        },
        0
      );
    },
    sumCalAmount(key, key2) {
      const outItems = this.mainList.dataset;
      return _.reduce(
        outItems,
        (sum, item) => {
          const amount1 = parseFloat(item[key]);
          const amount2 = parseFloat(item[key2]);
          const calVal = amount1 - amount2;
          return sum + (calVal > 0 ? calVal : 0);
        },
        0
      );
    },
    isShow(conditions) {
      return checkPermissions(conditions);
    },
    fetchProvider() {
      ApiService.setHeader();
      ApiService.get(`providers/getAll`).then(({ data }) => {
        this.listProviders = data.data.providers;
        this.listProviders.map((element) => {
          let provider = {
            id: element.id,
            name: element.name,
            suggestionName: element.name,
          };
          this.optionsProvider[0].data.push(provider);
        });
        let firstProvider = {
          id: null,
          name: 'Tất cả NCC',
          suggestionName: 'Tất cả NCC',
        };
        this.optionsProvider[0].data.unshift(firstProvider);
        this.filteredOptionsProvider = [...this.optionsProvider[0].data];
      });
    },
    onSelectedProvider(option) {
      this.searchProvider = option.item.name;
      this.providerSelected = option.item.id;
    },
    onInputChangeProvider(text) {
      this.searchProvider = text;
      const filteredData = this.optionsProvider[0].data
        .filter((item) => {
          return item.name.toLowerCase().indexOf(text.toLowerCase()) > -1;
        })
        .slice(0, this.limit);
      this.filteredOptionsProvider = [...filteredData];
    },
    linkToDetail(item) {
      const queryObj = {
        providerId: item.id,
        providerName: item.name,
        fromDate: this.apiParams.fromDate,
        toDate: this.apiParams.toDate,
      };
      const { href } = this.$router.resolve({
        name: 'list-debt-supplierduedate',
        query: queryObj,
      });
      window.open(href, '_blank');
    },
  },
  computed: {
    ...mapGetters(['breadcrumbs', 'pageTitle', 'layoutConfig']),
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'));
    },
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig('loader.logo');
    },
    getOffsetItem() {
      return (this.apiParams.page - 1) * this.apiParams.pageSize;
    },
  },
  beforeRouteUpdate: function (to, from, next) {
    this.mode = to.params.mode;
    next();
  },
  watch: {
    mode: function (val) {
      this.mode = val;
      this.fetchMainData();
      this.loadBreadcum();
    },
  },
};
</script>

<style lang="scss">
.debts-providers {
  .icon:hover {
    background-color: #90c6fc;
  }
  .sttClass {
    width: 20px;
  }
  .break-line {
    white-space: break-spaces;
  }
  .link {
    color: unset;
  }
  .link:hover {
    text-decoration: underline !important;
  }
  .page-item.disabled {
    cursor: not-allowed;
    &.bv-d-xs-down-none {
      .page-link {
        margin: 0.35rem 0.25rem;
        text-align: center;
      }
    }
  }
}
</style>
